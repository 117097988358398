import React from "react";
import "../index.css";
import { Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { animations } from "../constants";

const Regulations = ({ title, descr }) => {
  console.log("Regulations component props:", { title, descr });

  if (!title) {
    return <div>Loading or No Data Available</div>; // Можна вивести, коли дані не передано
  }

  // Вибір масиву для відображення
  let articles = [];

  if (title === "2024") {
    articles = descr.regulationsContent2024 || []; // Використовуємо regulationsContent2024
  } else if (title === "2023") {
    articles = descr.regulationsContent || []; // Використовуємо regulationsContent
  } else {
    articles = descr.regulationsContent || []; // Значення за замовчуванням
  }// Використовуємо regulationsContent, якщо він існує

  return (
    <Container
      sx={{ paddingTop: "90px" }}
      component={motion.div}
      variants={animations.sectionAnimation}
      initial="offscreen"
      animate="onscreen"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* Відображення контенту */}
      {articles.map((article, id) => (
        <Typography
          key={id}
          variant="body1"
          sx={{
            whiteSpace: "pre-line",
            fontWeight: article.bold ? 700 : 400,
            fontStyle: article.italic ? "oblique" : "normal",
            textAlign: article.center
              ? "center"
              : article.right
              ? "right"
              : "justify",
            margin: "1em 0",
          }}
        >
          {article.content}
        </Typography>
      ))}
    </Container>
  );
};

export default Regulations;