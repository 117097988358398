const headerLinks = [
  {
    name: "Головна",
    link: "/",
    id: 1,
  },
  {
    name: "Документи",
    link: "documents/sertificate",
    id: 2,
  },

  {
    name: "судді",
    link: "personnel",
    id: 3,
  },
  {
    name: "Третейський збір",
    link: "collecting",
    id: 4,
  },
  {
    name: "Оголошення",
    link: "advertisement",
    id: 5
  },
  {
    name: "Контакти",
    link: "contacts",
    id: 6 ,
  },
];

const subHeaderMenu = {
  standing:{
    name: "Положення",
    link: "standing",
    id: 1,
  },
  regulation2024:{
    name: "Регламент",
    link: "order2024",
    id: 2,
  },
  regulation:{
    name: "Регламент(09.02.2021)",
    link: "order2023",
    id: 3,
  },


  sertificate:  {
    name: "Свідоцтво",
    link: "sertificate",
    id:5, 
  },
  agreement:  {
    name: "Третейська угода",
    link: "agreement",
  },
};



const documentsLinks = [


];

const aboutLinks = [
  {
    name: "Третейський збір",
    link: "/about/collecting",
  },
  {
    name: "Судді",
    link: "/about/judges",
  },
];

export default { headerLinks, documentsLinks, aboutLinks, subHeaderMenu };
